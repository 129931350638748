$hero-tile: url('../assets/hero-tile.jpeg')

.archives-heading
  text-transform: uppercase
  letter-spacing: 2px
  background-color: rgba(237, 28, 35, 0.87)
  padding: 15px
  border-radius: 2px

  @media screen and (max-width: 768px)
    font-size: 22px

.page-header
  height: 30rem
  background-image: $hero-tile
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  background-size: cover
  background-position-y: center

.current-docs
  text-align: center