@import './Colors.sass'

.admin-panel 
  display: flex
  flex-direction: column
  justify-content: center

button:disabled 
  cursor: not-allowed

.current-docs, .archive-docs
  column-count: 3
  padding: 0
  @media screen and (max-width: 768px)
    column-count: 2

.doc:hover 
  color: #A11D33

li 
  list-style: none

.bg-primary-cl 
  background-color: $color-primary
  color: #F7F7F7

.bg-primary-cl:hover 
  background-color: #A11D33
  color: #F7F7F7

.auth-form
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100vh
  background-color: #990000
  background-image: linear-gradient(147deg, #bb0e0e 0%, #660909 74%)

form
  min-width: 400px

.form-control
  border: none
  border-bottom: 1px solid #bbbbbb
  padding: 0
  border-radius: 0

.form-control:focus
  box-shadow: none

.login-btn
  width: 100%

.user-pill
  cursor: pointer
  font-size: 35%
  vertical-align: middle
  text-transform: uppercase
  letter-spacing: 1px
  background-color: $color-primary
  border-radius: 15px
  padding: 3px 15px
  color: white
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s

.user-pill:hover
  background-color: #990000