@import '../stylesheets/Colors'

body
  font-family: 'Roboto', sans-serif
  background-color: #f5f5f5
  overflow-x: hidden

a
  color: black

::selection
  background-color: $color-primary
  color: #FFFFFF

.color-primary
  color: $color-primary