$hero-tile: url('../assets/hero-tile.jpeg')

.title
  font-size: 60px
  text-transform: uppercase
  letter-spacing: 10px

.eelanadu-logo
  width: 350px
  @media screen and (max-width: 768px)
    width: 250px

.bio
  text-transform: uppercase
  letter-spacing: 2px

.heading
  text-transform: uppercase
  letter-spacing: 2px

.issue-preview
  display: flex
  justify-content: center

.older-issues
  height: fit-content

.header
  height: 35rem
  background-image: $hero-tile
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  background-size: cover
  background-position-y: center

.btn
  text-transform: uppercase
  letter-spacing: 1px
  font-size: 14px

